import { Component } from 'react';

import { NoteContext } from 'contexts/NoteContext';
import './ShareNotePopup.scss';
import { AiFillCloseCircle, AiOutlineCopy } from 'react-icons/ai';
import FadingDots from "react-cssfx-loading/lib/FadingDots";


export default class ShareNotePopup extends Component {
	static contextType = NoteContext;

	constructor(props){
		super(props)
		this.state = {
			loading: true,
		}

	}

	handleSubmit = async e => {
		e.preventDefault()
		// console.log(e)
		if(!this.context.selectedNote.id){
			return
		}
		let data = { shared_access: 'none' }
		Object.keys(data).forEach(key => {
			data[key] = e.target[key].value
		})

		this.setState({ ...this.state, saving: true })
		await this.context.updateNoteSharing(data)
		this.setState({ ...this.state, saving: false })
	}


	selectURL = e => {
		e.target.select();
	}

	componentDidMount(){
		if(!this.context.selectedNote.shareLink){
			// generate link if doesn't exists already
			fetch(`/api/note/link/${this.context.selectedNote.id}`)
			.then(res => res.json())
			.then(res => {
				this.setState({ ...this.state, loading: false, share_url: res.link })
			})
			.catch(err => console.log(err))
		} else {
			this.setState({ ...this.state, loading: false, share_url: this.context.selectedNote.shareLink })
		}
	}

	render() {

		return (
			<div id="settings-popup-container-main">
				<div className="hiding_layout" onClick={this.props.hide}></div>
				<div className="poopup_main_content">
					<button onClick={this.props.hide} className="close_cross_btn"><AiFillCloseCircle/></button>
					<div className="settings_popup_inner share_popup_inner">
						{this.state.loading ?
						<div className="loader_container">
							<FadingDots width="50px" height="50px" color="#62d3f1" />
						</div>
						:
						<>
						<h2>Share Note</h2>
						<form onSubmit={this.handleSubmit} className="share_note_form">
							<label>
								<span>Share URL</span>
								<div className="share_link">
									<input type="text" name="share_url" value={window.location.origin +'/note/'+ this.state.share_url} onClick={this.selectURL} onInput={e => e.preventDefault()}/>
									<AiOutlineCopy className="copy_link_icon"/>
								</div>
							</label>
							<div className="bi_column">
								<label>
									<span>Anyone with the link can</span>
									<select name="shared_access" defaultValue={this.context.selectedNote.sharedAccessType}>
										<option value="" disabled>--Select Shared Access Type--</option>
										<option value='none' >Have No Access</option>
										<option value='view' >View Only</option>
										{/* <option value='edit' >View & Edit</option> */}
									</select>
								</label>
								<input type="submit" value="Save"/>
							</div>
						</form>
						</>}
					</div>
				</div>
			</div>

		);
	}
}