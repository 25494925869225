import { Component } from 'react';
import { NoteContext } from 'contexts/NoteContext';
import { UserContext } from 'contexts/UserContext';
import Wrapper from 'pages/Wrapper';
import UserSettings from 'components/UserSettings/UserSettings';
import ShareNotePopup from 'components/ShareNotePopup/ShareNotePopup';

import Editor  from '@toast-ui/editor';

import chart from '@toast-ui/editor-plugin-chart';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';
import uml from '@toast-ui/editor-plugin-uml';
import Prism from 'prismjs';

import './Dashboard.scss';

import deleteIcon from 'assets/delete.svg';
import logoutIcon from 'assets/logout.svg';
import gearIcon from 'assets/gear.svg';
import downloadIcon from 'assets/download.svg';
import shareIcon from 'assets/share.svg';

export default class Dashboard extends Component {
	static contextType = UserContext;
	render(){
		return <DashboardComponent userContext={this.context} />
	}
}
class DashboardComponent extends Component {
	static contextType = NoteContext;
	
	constructor(props) {
		super(props)
		this.state = {
			saving: '',
			note: {},
			showSettings: false,
			showSharePopup: false,
		}
	}

	handleChange = (e) => {
		let c = this.editor?.getMarkdown()
		let n = {...this.state.note, content: c}
		this.context.handleChange(n)
	}

	componentDidMount(){
		let chartOptions = {
			minWidth: 100,
			maxWidth: 600,
			// minHeight: 100,
			maxHeight: 300
		}

		const options = {
			el: document.querySelector('#noteEditor'),
			previewStyle: "tab",
			height: "100vh",
			initialEditType: "wysiwyg",
			placeholder: '# Note title',
			events: {
				keyup: this.handleChange,
				command: this.handleChange,
				// change: this.handleChange,
				// caretChange: e => console.log(e, 'caret change'),
				// changeLanguage: e => console.log(e, 'language change'),
				// addImageBlobHook: e => console.log(e),
			},
			usageStatistics: false,
			initialValue: this.context.selectedNote.content || "# \n",
			useCommandShortcut: true,
			toolbarItems: [
				['heading', 'bold', 'italic', 'strike'],
				['hr', 'quote'],
				['ul', 'ol', 'task', 'indent', 'outdent'],
				['table', 'image', 'link'],
				['code'],
				[{
					el: this.codeBlock(),
					tooltip: 'Code Block',
				},{
					el: this.downloadNote(),
					tooltip: 'Download',
				},{
					el: this.shareNote(),
					tooltip: 'Share',
				},{
					el: this.deleteNote(),
					tooltip: 'Delete Note',
				},{
					el: this.logout(),
					tooltip: 'Logout'
				},{
					el: this.settings(),
					tooltip: 'Settings'
				}]
			],
			plugins: [
				[chart, chartOptions],
				colorSyntax,
				[codeSyntaxHighlight, { highlighter: Prism }],
				uml,
				tableMergedCell
			],
			theme: 'dark',
		}

		this.editor = new Editor( options )
		window.onkeydown = e => {
			if(!e.ctrlKey || !e.shiftKey){
				return
			}
			let text = e.code?.slice(0,-1), number = Number(e.code?.slice(-1))
			if((text == 'Numpad' || text == 'Digit') && number > 0 && number < 7){
				this.editor.exec('heading', {level: number })
			}
		}
		this.editor.wwEditor.schema.nodes.codeBlock.defaultAttrs.language = this.props.userContext.user.defaultCodingLang
		window.editor = this.editor
	}

	codeBlock = () => {
        const button = document.createElement('button');
        button.className = 'hks-custom-button codeblock toastui-editor-toolbar-icons';
		button.setAttribute('aria-label', 'Code Block');
        button.addEventListener('click', () => {
			let info = this.editor.getRangeInfoOfNode()
			if(info.type == 'codeBlock'){
				this.editor.replaceSelection(' ', info.range[0]-1, info.range[0])
			}else{
				this.editor.exec('codeBlock');
				info = this.editor.getRangeInfoOfNode()
				this.editor.replaceSelection(' ', info.range[1]+1, info.range[1]+1)
			}
			this.handleChange()
        })
        return button;
    }
	
	downloadNote = () => {
		const button = document.createElement('button');
        button.className = 'hks-custom-button download hide-on-empty';
		button.setAttribute('aria-label', 'Download Note');
		button.innerHTML = `<img src="${downloadIcon}" alt="download" />`;
        button.addEventListener('click', () => {
			let a = document.createElement('a')
			a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.editor.getMarkdown())
			a.download = this.context.selectedNote.title + '.md'
			a.click()
        })
        return button;
    }
	
	shareNote = () => {
		const button = document.createElement('button');
		button.setAttribute('aria-label', 'Share Note');
        button.className = 'hks-custom-button share  hide-on-empty';
		button.innerHTML = `<img src="${shareIcon}" alt="share" />`;
        button.addEventListener('click', () => {
			this.setState({...this.state, showSharePopup: true})
        })
        return button;
    }
	
	deleteNote = () => {
		const button = document.createElement('button');
		button.setAttribute('aria-label', 'Delete Note');
        button.className = 'hks-custom-button delete-note-btn  hide-on-empty';
        button.innerHTML = `<img src="${deleteIcon}" alt="delete" />`;
        button.addEventListener('click', () => {
			this.context.deleteNote()
        })
        return button;
    }
	
	logout = () => {
		const button = document.createElement('button');

        button.className = 'hks-custom-button logout-btn';
		button.setAttribute('aria-label', 'Logout');
        button.innerHTML = `<img src="${logoutIcon}" alt="logout" />`;
        button.addEventListener('click', () => {
			this.props.userContext.logUserOut()
        });
		
        return button;
    }
	
	settings = () => {
		const button = document.createElement('button');
        button.className = 'hks-custom-button settings-btn';
		button.setAttribute('aria-label', 'Settings');
        button.innerHTML = `<img src="${gearIcon}" alt="Settings" />`;
        button.addEventListener('click', () => {
			this.setState({...this.state, showSettings: true})
        });

        return button;
    }

	hideSttings = () => {
		this.setState({...this.state, showSharePopup: false, showSettings: false})
	}

	prismLangsSorted = () => {
		let langsArray = Object.keys(Prism.languages)
		langsArray.sort()
		let langsSorted = {}
		langsArray.forEach(lang => {
			langsSorted[lang] = Prism.languages[lang]
		})
		return langsSorted
	}
	
	toggleButtons = (display) => {
		let buttons = document.querySelectorAll('.hide-on-empty')
		buttons.forEach(button => {
			button.style.display = display
		})
	}

	render() {
		let selectedNote = this.context.selectedNote

		if(selectedNote?.id){
			this.toggleButtons('block')
			
			document.title = selectedNote.title
			if( selectedNote.id !== this.state.note.id ){
				this.editor?.setMarkdown(selectedNote?.content)
				this.setState({...this.state, note: selectedNote, saving: ''})
			}
		}else{
			document.title = 'Create new note...'
			this.toggleButtons('none')

			if(this.state.note.id){
				this.editor?.setMarkdown('# ')
				this.setState({...this.state, note: selectedNote, saving: ''})
			}
		}
		return (
			<Wrapper>
				<div className="dashboard">
					{this.state.showSettings && <UserSettings hide={this.hideSttings} userContext={this.props.userContext} />}
					{this.state.showSharePopup && <ShareNotePopup hide={this.hideSttings} userContext={this.props.userContext} />}
					<div id='noteEditor'></div>
					<span className={`saving_status ${this.context.saving ? 'saving_note' : ''}`}>{this.context.saving ? 'saving...' : ''}</span>
				</div>
			</Wrapper>
		)
	}
}