import { Component } from 'react';
import { NoteContext } from 'contexts/NoteContext';
import { UserContext } from 'contexts/UserContext';
import Error404 from 'pages/NotFound/NotFound';

import Editor  from '@toast-ui/editor';

import chart from '@toast-ui/editor-plugin-chart';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';
import uml from '@toast-ui/editor-plugin-uml';
import Prism from 'prismjs';

import './Note.scss';
import BouncingBalls from 'react-cssfx-loading/lib/BouncingBalls';

export default class Note extends Component {
	static contextType = NoteContext;
	
	constructor(props) {
		super(props)
		this.state = {
			note: {},
			loading: true,
		}
	}
	
	componentDidMount() {

		let noteID = window.location.href.split('/note/')[1].split('?')[0].split('/')[0].replaceAll(/[^0-9a-zA-Z]/gi, '')
		fetch('/api/get_note/' + noteID)
		.then(res => res.json())
		.then(res => {
			this.setState({
				note: res,
				loading: false,
			})
			this.initializeEditor()
		})
	}

	initializeEditor = () => {
		let chartOptions = {
			minWidth: 100,
			maxWidth: 600,
			// minHeight: 100,
			maxHeight: 300
		}
		const options = {
			el: document.querySelector('#note_content'),
			height: "100vh",
			usageStatistics: false,
			viewer: true,
			initialValue: this.state.note.content || "",
			previewStyle: "tab",
			plugins: [
				[chart, chartOptions],
				colorSyntax,
				[codeSyntaxHighlight, { highlighter: Prism }],
				uml,
				tableMergedCell
			],
			theme: 'dark',
		}
		
		this.editor = new Editor.factory( options )
		// window.editor = this.editor
	}
	
	render() {
		if(this.state.loading){
			return <div className="pagePreloader">
				<BouncingBalls width='30px' height="30px" color="#03a9f4" />
			</div>
		}
		if( !this.state.note.id ){
			return(
				<Error404 />
			)
		}
		document.title = this.state?.note?.title
		return (
			<div className="view_note cb-theme-default">
				<div id='note_content'></div>
			</div>
		)
	}
}